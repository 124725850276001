import * as FullStory from '@fullstory/browser';

import { createStore, combineReducers, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import ReduxThunk from 'redux-thunk';
import MainNavigator from './navigation/MainNavigator';

import createAccountReducer from './store/reducers/createAccount';
import portfolioReducer from './store/reducers/portfolio';
import homeReducer from './store/reducers/home';
import transfersReducer from './store/reducers/transfers';
import authReducer from './store/reducers/auth';
import accountReducer from './store/reducers/account';

import './App.css';

const { REACT_APP_FULLSTORY_ID, REACT_APP_ENV_TYPE } = process.env;

const rootReducer = combineReducers({
  createAccount: createAccountReducer,
  portfolio: portfolioReducer,
  home: homeReducer,
  transfers: transfersReducer,
  auth: authReducer,
  account: accountReducer
});

let store = createStore(rootReducer, applyMiddleware(ReduxThunk));

const devMode = (REACT_APP_ENV_TYPE === 'dev') ? true : false;
FullStory.init({ orgId: REACT_APP_FULLSTORY_ID, devMode: devMode, debug: (devMode ? true : false) });

function App() {

  return (
    <Provider store={store}>
      <MainNavigator />
    </Provider>

  )
}

export default App;
